<template>
  <div>

    <b-table small
        striped hover 
        :items="listeTiers" 
        :fields="filtreColonnes"
        :filter="filter"
        :per-page="parPage"
        :current-page="pageCourante"
        show-empty
        empty-filtered-text="Aucune données trouvées"
        empty-text="Aucune données trouvées"
        @filtered="onFiltered"
    >

      <template #cell(actions)="row">
          <b-button squared @click="afficherModalConfirmationSuppression(row.item, row.index, $event.target)" variant="danger"><font-awesome-icon icon="trash-alt" /></b-button>
      </template>

    </b-table>

    <b-form-group
        label=""
        label-for="Pagination"
    >

      <b-pagination
          id="pagination"
          v-model="pageCourante"
          :total-rows="lignesTotales"
          :per-page="parPage"
          align="fill"
      ></b-pagination>

    </b-form-group>

    <b-modal id="modalSupprimerTiers" title="Confirmation">
      <template v-if="messageErreurSuppressionDuType">
          <p><b-alert show variant="danger">{{ messageErreurSuppressionDuType }}</b-alert></p>
      </template>

      <p class="my-4">Etes-vous certain de vouloir supprimer le tiers <b>{{ tiersLibelleEnCours }}</b> ?</p>

      <template #modal-footer="{cancel}">

          <b-button squared @click="cancel()">
          Non
          </b-button>
          <b-button squared variant="success" @click="validationSuppressionTiers">
          Oui
          </b-button>

      </template>

    </b-modal>

  </div>
</template>

<script>

  import url from '../router/url.js';
  const axios = require('axios');

  export default {
    props: {
      'compteID' : String,
    },
    data() {
      return {
        filter:'',
        parPage:20,
        pageCourante: 1,
        lignesTotales : 1,
        tiersLibelleEnCours : '',
        idTiersEnCours : '',
        idUtilisateurEnCours : '',
        messageErreurSuppressionDuType : '',
        listeTiers : [],
        colonnes: [
          {
            label: 'ID tiers',
            key: 'ID_TIERS',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Libellé',
            key: 'LIBELLE',
            isVisible: false,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Société',
            key: 'TIERS_NOMSOCIETE',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: '',
            key: 'actions',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
        ],
      }
    },
    methods: {
      getTiers() {
        axios.get(url.GetMappageTiers + this.compteID).then(response => {

          if (response.data) {
            this.listeTiers = [];
            this.listeTiers = response.data;
          }
        
        }).catch(function (error) {
          alert('Erreur lors de la récupération du mappage. ' + error);
        });
      },
      onFiltered(filteredItems) {
        this.lignesTotales = filteredItems.length
        this.pageCourante = 1
      },
      afficherModalConfirmationSuppression(rowItem) {
        this.tiersLibelleEnCours = rowItem.TIERS_NOMSOCIETE;
        this.idTiersEnCours = rowItem.ID_TIERS;
        this.idUtilisateurEnCours = rowItem.ID_UTILISATEUR;
        this.$bvModal.show('modalSupprimerTiers');
      },
      validationSuppressionTiers() {

        let data = { idUtilisateur: this.idUtilisateurEnCours, idTiers: this.idTiersEnCours};

        axios.delete(url.SupprimeMappageTiers, {data: data}).then(response => {
          var isDeleted = response.data == "1";

          if (isDeleted) {
            this.getTiers();
            this.$bvModal.hide('modalSupprimerTiers');            
          } else {
            this.messageErreurSuppressionDuType = 'Erreur lors de la suppression du tiers.';
          }
            
        }).catch(function (error) {
          alert('Erreur lors de la suppression du tiers. ' + error);
        });
      }
    },
      computed: {
        filtreColonnes() {
          return this.colonnes.filter(field => field.isVisible);
        }
      },
      mounted: function() {
        this.lignesTotales = this.listeTiers.length;
        this.getTiers();
      },
      watch: {
        compteID: function() {
          this.getTiers();
        }
      }
    }

</script>
