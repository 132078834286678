import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import '@popperjs/core/dist/cjs/popper'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faCheck, faTrashAlt, faDownload, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import store from './store/index.js'

library.add(faPlus, faCheck, faTrashAlt, faDownload, faCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon),
Vue.component('font-awesome-layers', FontAwesomeLayers),

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
