<template>
  <div>

    <b-table small
      striped hover 
      :filter="filter"
      :items="comptesUtilisateurs" 
      :fields="filtreColonnes"
      :per-page="parPage"
      :current-page="pageCourante"
      show-empty
      empty-filtered-text="Aucune données trouvées"
      empty-text="Aucune données trouvées"
      :selectable="isSelectable"
      select-mode="single"
      selected-variant="primary"
      @row-clicked="cliqueSurLaLigne"
      @row-selected="onRowSelected"
      @filtered="onFiltered"
      ref="tableauComptes"
    >

      <template #cell(ADMIN)="row">
        <template v-if="row.item.ADMIN == 'O'">
          <font-awesome-icon icon="check" />
        </template>
      </template>

      <template #cell(actions)="row">
        <b-dropdown right text="Right align">
          <template #button-content>
          <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
          </template>
          <b-dropdown-item @click="afficheLeModalModifierLeCompte(row.item, row.index, $event.target)">Modifier...</b-dropdown-item>
          <b-dropdown-item @click="afficheLeModalSupprimerLeCompte(row.item, row.index, $event.target)">Supprimer le compte</b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>

    <b-form-group
      label=""
      label-for="Pagination"
    >

      <b-pagination
        id="pagination"
        v-model="pageCourante"
        :total-rows="lignesTotales"
        :per-page="parPage"
        align="fill"
      ></b-pagination>

    </b-form-group>

    <b-modal id="modalModifierLeCompte" :title="'Modification du compte ' + utilisateurEnCours.libelle">

      <template v-if="messageErreurMiseAJourDuCompte">
        <p><b-alert show variant="danger">{{ messageErreurMiseAJourDuCompte }}</b-alert></p>
      </template>

      <b-form id="formModifierLeMotDePasse" @submit="validationModificationDuCompte">

        <inputChangerMotDePasse @motDePasse="setMotDePasse" @motDePasseValide="setMotDePasseValide" label="Nouveau mot de passe" />

        <b-form-checkbox
          id="checkboxAdministrateur"
          v-model="modificationDroitAdmin"
          name="checkbox-isAdmin"
          >
          Administrateur
        </b-form-checkbox>

      </b-form>

      <template #modal-footer="{cancel}">

        <b-button squared @click="cancel()">
          Annuler
        </b-button>
        <b-button squared type="submit" :disabled=!motDePasseValide form="formModifierLeMotDePasse" variant="success">
          Mettre à jour
        </b-button>

      </template>

    </b-modal>

    <b-modal id="modalSupprimerLeCompte" title="Suppresion du compte">

      <template v-if="messageErreurSuppressionDuCompte">
        <p><b-alert show variant="danger">{{ messageErreurSuppressionDuCompte }}</b-alert></p>
      </template>

      <p>Etes-vous sur de vouloir supprimer le compte <b>{{ utilisateurEnCours.libelle }}</b> ?</p>

      <template #modal-footer="{cancel}">

        <b-button squared @click="cancel()">
          Non
        </b-button>
        <b-button squared variant="success" @click="validationSuppressionCompte()">
          Oui
        </b-button>

      </template>

    </b-modal>

  </div>
</template>


<script>

  import url from '../router/url.js';
  import inputChangerMotDePasse from '../components/InputChangerMotDePasse.vue';
  const axios = require('axios');

  export default {
    props: {
      'isSelectable': Boolean,
      'filter': String,
      'parPage': Number,
    },
    components: {
      inputChangerMotDePasse
    },
    data() {
      return {
        pageCourante: 1,
        lignesTotales : 1,
        compteIdEnCours: '',
        nouveauMotDePasse : '',
        motDePasseValide : false,
        modificationDroitAdmin : false,
        messageErreurMiseAJourDuCompte: '',
        messageErreurSuppressionDuCompte : '',
        comptesUtilisateurs: [],
        utilisateurEnCours : {
          id: '',
          libelle: '',
          isAdmin: '',
        },
        colonnes: [
          {
            label: 'ID',
            key: 'ID',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'

          },
          {
            label: 'Libellé',
            key: 'LIBELLE',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Administrateur',
            key: 'ADMIN',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: '',
            key: 'actions',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
        ], 
      }
    },
    methods: {
      getComptesUtilisateurs() {

        axios.get(url.GetTousLesUtilisateurs).then(response => {

          this.comptesUtilisateurs = response.data;
        
        }).catch(function (error) {
          alert('Erreur lors de la récupération des comptes. ' + error);
        });

      },
      afficheLeModalModifierLeCompte(rowItem) {

        this.messageErreurMiseAJourDuCompte = '';

        this.utilisateurEnCours.id = rowItem.ID;
        this.utilisateurEnCours.libelle = rowItem.LIBELLE;

        if (rowItem.ADMIN == 'O') {
          this.utilisateurEnCours.isAdmin = true;
        } else {
          this.utilisateurEnCours.isAdmin = false;
        }

        this.modificationDroitAdmin = this.utilisateurEnCours.isAdmin;

        this.nouveauMotDePasse = '';
        this.$bvModal.show('modalModifierLeCompte');
      },
      afficheLeModalSupprimerLeCompte(rowItem) {
        this.utilisateurEnCours.id = rowItem.ID;
        this.utilisateurEnCours.libelle = rowItem.LIBELLE;
        this.messageErreurSuppressionDuCompte = '';
        this.$bvModal.show('modalSupprimerLeCompte');
      },
      onFiltered(filteredItems) {
        this.lignesTotales = filteredItems.length;
        this.pageCourante = 1;
      },
      validationSuppressionCompte() {

        axios.delete(url.SuppressionCompte + this.utilisateurEnCours.id).then(response => {

          var isDeleted = response.data == "1";

          if (isDeleted) {
            this.getComptesUtilisateurs();
            this.$bvModal.hide('modalSupprimerLeCompte');            
          } else {
            this.messageErreurSuppressionDuCompte = 'Erreur lors de la suppression du compte.';
          }
        
        }).catch(function (error) {
          alert('Erreur lors de la suppression du compte. ' + error);
        });
        
      },
      validationModificationDuCompte(event) {
        event.preventDefault();

        var admin;

        if (this.modificationDroitAdmin) {
          admin = 'O';
        } else {
          admin = 'N'
        }

        let data = { id: this.utilisateurEnCours.id, libelle: this.utilisateurEnCours.libelle, motDePasse: this.nouveauMotDePasse, admin:admin };

        axios.put(url.UpdateCompte, data).then(response => {

          var isUpdated = response.data == "1";

          if (isUpdated) {
            this.getComptesUtilisateurs();
            this.$bvModal.hide('modalModifierLeCompte');
          } else {
            this.messageErreurMiseAJourDuCompte = 'Erreur lors de la mise à jour du compte.';
          }
        
        }).catch(function (error) {
          alert('Erreur lors de la mise à jour du compte. ' + error);
        });

      },
      cliqueSurLaLigne(rowItem) {
        this.$emit('ligneID', rowItem.ID);
      },
      onRowSelected(item) {
        if (item.length > 0) {
          this.$emit('compteSelectionne', true);
        } else {
          this.$emit('compteSelectionne', false);
        }
      },
      setMotDePasse(valeur) {
        this.nouveauMotDePasse = valeur;
      },
      setMotDePasseValide(valeur) {
        this.motDePasseValide = valeur;
      },
        
    },
    computed: {
      filtreColonnes() {
        return this.colonnes.filter(field => field.isVisible);
      }
    },
    mounted: function() {
      this.lignesTotales = this.comptesUtilisateurs.length;
      this.getComptesUtilisateurs();
    },


  }

</script>
