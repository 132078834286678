<template>
  <div>

    <b-container fluid>

      <b-row>

        <b-col cols="4">
          <p>Séléctionnez un compte</p>
        </b-col>

        <b-col cols="8">
          <p>
            Liste des types de documents associés au compte  
            <span class="text-right bouton-ajouter-type"> 
              <b-button squared @click="afficheModalAjouterUnNouveauType" :disabled=!disabledBouttonAjouterType variant="success">
                <font-awesome-icon icon="plus" /> Ajouter un type</b-button>
            </span>
          </p>
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="4">

          <TableauDesComptes :isSelectable=true @ligneID="getLigneID" @compteSelectionne="siCompteSelected"></TableauDesComptes>

        </b-col>

        <b-col cols="8">

          <template v-if="disabledBouttonAjouterType">
            <TableauDesTypesDeDocuments 
              :compteID=compteIDSelectionnee
              ref="tableauTypeDeDoc"
            ></TableauDesTypesDeDocuments>  
          </template>

        </b-col>

      </b-row>

        <b-modal id="modalAjouterUnNouveauType" title="Ajout d'un nouveau type de document">

          <template v-if="messageErreurAjoutNouveauType">
            <p><b-alert show variant="danger">{{ messageErreurAjoutNouveauType }}</b-alert></p>
          </template>

          <b-form id="formAjouterUnNouveauType" @submit="validationAjouterUnNouveauType">

            <b-form-select v-model="selectionDuType" :options="typesDeDocumentsGed"></b-form-select>
              
          </b-form>

            <template #modal-footer="{cancel}">

              <b-button squared @click="cancel()">
                Fermer
              </b-button>
              <b-button squared type="submit" form="formAjouterUnNouveauType" variant="success">
                Ajouter
              </b-button>

            </template>

        </b-modal>

    </b-container>
   
  </div>
</template>


<script>

  import TableauDesTypesDeDocuments from '../../components/TableauDesTypesDeDocuments.vue';
  import TableauDesComptes from '../../components/TableauDesComptes.vue';
  import url from '../../router/url';
  const axios = require('axios');

  export default {
    components: {
      TableauDesTypesDeDocuments,
      TableauDesComptes,
    },
    data() {
      return {
        compteIDSelectionnee: null,
        filter:'',
        parPage:20,
        pageCourante: 1,
        formNouveau: {
          libelle : '',
        },
        typesDeDocumentsGed : [],
        selectionDuType : null,
        messageErreurAjoutNouveauType : '',
        disabledBouttonAjouterType : false,
      }
    },
    methods: {
      getLesTypesDeDocSurGed() {

        axios.get(url.GetTypesDocGed).then(response => {

          for (let i = 0; i < response.data.length; i++) {
            this.typesDeDocumentsGed.push({value:response.data[i].ID_TYPE_DOC, text:response.data[i].LIBELLE_TYPE});
          }
          
        }).catch(function (error) {
          alert('Erreur lors de la récupération des types de documents. ' + error);
        });
    
      },
      getLigneID(value) {
        this.compteIDSelectionnee = value;
      },
      siCompteSelected(value) {
        this.disabledBouttonAjouterType = value;
      },
      afficheModalAjouterUnNouveauType() {
        this.formNouveau.libelle = '';
        this.messageErreurAjoutNouveauType = '';
        this.$bvModal.show('modalAjouterUnNouveauType');
      },
      validationAjouterUnNouveauType(event) {

        event.preventDefault();

        var formData = new FormData();
        formData.append('idUtilisateur', this.compteIDSelectionnee);
        formData.append('idTypeDoc', this.selectionDuType);

        axios.post(url.CreationMappageTypesDocuments, formData).then(response => {

          var isAdded = response.data == "1";
          var mappageExistant = response.data == "0";
          var utilisateurInexistant = response.data == "-1";
          var typeDocumentInexistant = response.data == "-2";

          if (isAdded) {
            this.$refs.tableauTypeDeDoc.getLesTypesDeDocumentsDeLUtilisateur();
            this.$bvModal.hide('modalAjouterUnNouveauType');
          } else if (mappageExistant) {
            this.messageErreurAjoutNouveauType = "Le mappage existe déjà.";
          } else if (utilisateurInexistant) {
            this.messageErreurAjoutNouveauType = "L'utilisateur n'existe pas.";
          } else if (typeDocumentInexistant) {
            this.messageErreurAjoutNouveauType = "Le type de document n'existe pas.";
          } else {
            this.messageErreurAjoutNouveauType = "Erreur lors de la création du compte.";
          }
        
        }).catch(function (error) {
          alert('Erreur lors de la création du mappage. ' + error);
        });
          
      },
      setFiltre(value) {
        this.filter = value;
      },
      setParPage(value) {
        this.parPage = value;
      }
    },
    mounted: function() {
      this.getLesTypesDeDocSurGed();
    }
  }

</script>

<style> 

  .bouton-ajouter-type {
    float:right;
    margin-top: -5px;
  }

</style>