<template>
  <div>

    <b-table small
      striped hover 
      :items="typesDeDocuments" 
      :fields="filtreColonnes"
      :filter="filter"
      :per-page="parPage"
      :current-page="pageCourante"
      show-empty
      empty-filtered-text="Aucune données trouvées"
      empty-text="Aucune données trouvées"
      selected-variant="danger"
      @filtered="onFiltered"
    >

      <template #cell(actions)="row">
          <b-button squared @click="afficherModalConfirmationSuppression(row.item, row.index, $event.target)" variant="danger"><font-awesome-icon icon="trash-alt" /></b-button>
      </template>

    </b-table>

    <b-form-group
      label=""
      label-for="Pagination" 
    >

    <b-pagination
      id="pagination"
      v-model="pageCourante"
      :total-rows="lignesTotales"
      :per-page="parPage"
      align="fill"
    ></b-pagination>

    </b-form-group>

    <b-modal id="modalSupprimerLeType" title="Confirmation">

      <template v-if="messageErreurSuppressionDuType">
        <p><b-alert show variant="danger">{{ messageErreurSuppressionDuType }}</b-alert></p>
      </template>

      <p class="my-4">Etes-vous certain de vouloir supprimer le type <b>{{ typesLibelleEnCours }}</b> ?</p>

      <template #modal-footer="{cancel}">

        <b-button squared @click="cancel()">
          Non
        </b-button>
        <b-button squared variant="success" @click="validationSuppressionType">
          Oui
        </b-button>

      </template>

    </b-modal>

  </div>
</template>


<script>

  import url from '../router/url';
  const axios = require('axios');

  export default {
    props: {
      'filter': String,
      'parPage': Number,
      'compteID' : String,
    },
    data() {
      return {
        pageCourante: 1,
        lignesTotales : 1,
        typesLibelleEnCours : '',
        idUtilisateurEnCours : '',
        idTypeDocEnCours : '',
        messageErreurSuppressionDuType : '',
        typesDeDocuments: [],
        colonnes: [
          {
            label: 'ID Utilisateur',
            key: 'ID_UTILISATEUR',
            isVisible: false,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'ID Type Doc',
            key: 'ID_TYPE_DOC',
            isVisible: false,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Libellé',
            key: 'LIBELLE',
            isVisible: false,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Type',
            key: 'LIBELLE_TYPE',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: '',
            key: 'actions',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
        ],
          
      }
    },
    methods: {
      getLesTypesDeDocumentsDeLUtilisateur() {

        axios.get(url.GetMappageTypesDocuments + this.compteID).then(response => {

          if (response.data) {
            this.typesDeDocuments = [];
            this.typesDeDocuments = response.data;
          }
        
        }).catch(function (error) {
          alert('Erreur lors de la récupération du mappage. ' + error);
        });

      },
      onFiltered(filteredItems) {
        this.lignesTotales = filteredItems.length;
        this.pageCourante = 1;
      },
      afficherModalConfirmationSuppression(rowItem) {
        this.typesLibelleEnCours = rowItem.LIBELLE_TYPE;
        this.idTypeDocEnCours = rowItem.ID_TYPE_DOC;
        this.idUtilisateurEnCours = rowItem.ID_UTILISATEUR;
        this.$bvModal.show('modalSupprimerLeType');
      },
      validationSuppressionType() {

        let data = { idUtilisateur: this.idUtilisateurEnCours, idTypeDoc: this.idTypeDocEnCours};

        axios.delete(url.SupprimeMappageTypesDocuments, {data: data}).then(response => {
          var isDeleted = response.data == "1";

          if (isDeleted) {
            this.getLesTypesDeDocumentsDeLUtilisateur();
            this.$bvModal.hide('modalSupprimerLeType');            
          } else {
            this.messageErreurSuppressionDuType = 'Erreur lors de la suppression du type.';
          }
          
        }).catch(function (error) {
          alert('Erreur lors de la suppression du type. ' + error);
        });
      }
    },
    computed: {
      filtreColonnes() {
          return this.colonnes.filter(field => field.isVisible);
        }
    },
    mounted: function() {
      this.getLesTypesDeDocumentsDeLUtilisateur();
      this.lignesTotales = this.typesDeDocuments.length;
    },
    watch: {
      compteID: function() {
          this.getLesTypesDeDocumentsDeLUtilisateur();
      }
    }
  }

</script>
