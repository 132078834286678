let api = "/api/";                  //prod
//let api = "http://172.26.0.2/";     //dev

let CheckSiUtilisateurExiste = api + "utilisateurValide/";
let GetInformationsUtilisateur = api + "utilisateurRead/";
let GetTousLesUtilisateurs = api + "utilisateurs";
let CreationCompte = api + "utilisateurCreate";
let UpdateCompte = api + "utilisateurUpdate";
let SuppressionCompte = api + "utilisateurDelete/";
let GetTypesDocGed = api + "typesDoc";
let GetMappageTypesDocuments = api + "typesDocMappageRead/";
let CreationMappageTypesDocuments = api + "typesDocMappageCreate";
let SupprimeMappageTypesDocuments = api + "typesDocMappageDelete";
let GetTiers = api + "tiers/";
let CreationMappageTiers = api + "tiersMappageCreate";
let GetMappageTiers = api + "tiersMappageRead/";
let SupprimeMappageTiers = api + "tiersMappageDelete";
let GetDocumentsParOT = api + "documentsByOt/";
let GetDocumentsParDate = api + "documentsByDates/";
let TelechargeLeDocument = api + "documentsDownload/"
let TelechargerPlusieursDocuments = api + "documentsMultiZip";
let GetDocumentsParFacture = api + "documentsByFacture/";
let ChangePasswordFirstConnexion = api + "utilisateurPasswordFirstConnexion";

export default {
    CheckSiUtilisateurExiste,
    GetInformationsUtilisateur,
    GetTousLesUtilisateurs,
    CreationCompte,
    UpdateCompte,
    SuppressionCompte,
    GetTypesDocGed,
    GetMappageTypesDocuments,
    CreationMappageTypesDocuments,
    SupprimeMappageTypesDocuments,
    GetTiers,
    CreationMappageTiers,
    GetMappageTiers,
    SupprimeMappageTiers,
    GetDocumentsParOT, 
    GetDocumentsParDate, 
    TelechargeLeDocument,
    TelechargerPlusieursDocuments,
    GetDocumentsParFacture,
    ChangePasswordFirstConnexion
}