import Vue from 'vue'
import VueRouter from 'vue-router'
import ListeDesDocuments from '../views/ListeDesDocuments.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import MappageDeTypesDeDocuments from '../views/Admin/MappageDeTypesDeDocuments.vue'
import GestionDesComptes from '../views/Admin/GestionDesComptes.vue'
import MappageDesTiers from '../views/Admin/MappageDesTiers.vue'
import Erreur404 from '../views/Erreur404.vue'
import store from '../store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ListeDesDocuments,
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/admin/mappage-types-de-documents',
    name: 'MappageDeTypesDeDocuments',
    component: MappageDeTypesDeDocuments,
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '/admin/gestion-des-comptes',
    name: 'GestionDesComptes',
    component: GestionDesComptes,
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '/admin/mappage-des-tiers',
    name: 'MappageDesTiers',
    component: MappageDesTiers,
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '*',
    component: Erreur404,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  var userLogged = store.state.isLogged;

  if (to.name == "Login" && userLogged) {
    next("/");
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!userLogged) {
      next("/login");
    } else {
      next();
    }

  } else {
    next();
  }

})

export default router
