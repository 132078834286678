<template>
  <div id="app">

    <br><br>

      <b-navbar fixed="top" toggleable="lg" type="dark" class="couleur-navbar">
        <b-navbar-brand :to="{ name: 'Home'}">Trans-Documents</b-navbar-brand>

        <b-navbar-nav class="ml-auto">

          <template v-if="$store.state.user.isAdmin">
            <b-nav-item-dropdown right>
              <template #button-content>
                Administration
              </template>
              <b-dropdown-item :to="{ name: 'GestionDesComptes'}">Gestion des comptes</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'MappageDesTiers'}">Mappage des tiers</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'MappageDeTypesDeDocuments'}">Mappage des types de documents</b-dropdown-item>
            </b-nav-item-dropdown>
          </template>

          <template v-if="$store.state.isLogged">

            <b-navbar-nav>
              <b-nav-item-dropdown right>
              <template #button-content>
                {{$store.state.user.name}}
              </template>
              <b-dropdown-item :to="{ name: 'Logout'}">Se déconnecter</b-dropdown-item>
            </b-nav-item-dropdown>

            </b-navbar-nav>
          </template> 
          <template v-else>  
            <b-navbar-nav>
              <b-nav-item :to="{ name: 'Login'}">Connexion</b-nav-item>
            </b-navbar-nav>      
          </template>
          
        </b-navbar-nav>
      </b-navbar>

      <br>

    <router-view/>
    
  </div>

</template>

<script>

  export default {
    created() {
      const titre = 'Trans-Documents';
      document.title = titre;
    }
  }

</script>

<style> 

  @import './assets/css/mesguen.css';

</style>

