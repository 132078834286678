<template>
  <div>

    <b-container fluid>

        <b-row>

            <b-col cols="3">

            <p><b-button squared block @click="afficheModalCreationCompte">Ajouter un compte</b-button></p>

            <CriteresDeRecherche @filtre="setFiltre" @parPage="setParPage"> </CriteresDeRecherche>  

            </b-col>

            <b-col cols="9">
            
                <TableauDesComptes 
                :filter=filter 
                :parPage=parPage 
                :isSelectable=false
                ref="tableauComptes"
                ></TableauDesComptes>

            </b-col>

        </b-row>

        <b-modal id="modalCreerUnNouveauCompte" title="Création d'un nouveau compte">

            <b-form id="formCreerUnNouveauCompte" @submit="validationCreerUnNouveauCompte">

            <template v-if="erreurCreationCompte">
                <p><b-alert show variant="danger">{{ erreurCreationCompte }}</b-alert></p>
            </template>

            <b-form-group
                id="input-group-1"
                label="Nom d'utilisateur"
                label-for="inputNomUtilisateur"
            >

            <b-form-input
                id="inputNomUtilisateur"
                v-model="formNouveau.nomUtilisateur"
                autocomplete="off" 
                type="text"
                required
            ></b-form-input>

            </b-form-group>

            <inputChangerMotDePasse @motDePasse="setMotDePasse" @motDePasseValide="setMotDePasseValide" label="Mot de passe" />

            <b-form-checkbox
                id="checkbox-1"
                v-model="formNouveau.isAdmin"
                name="checkbox-isAdmin"
            >
                Administrateur
            </b-form-checkbox>

            </b-form>
            <template #modal-footer="{cancel}">

                <b-button squared @click="cancel()">
                Annuler
                </b-button>
                <b-button squared :disabled=!motDePasseValide type="submit" form="formCreerUnNouveauCompte" variant="success">
                Créer
                </b-button>

            </template>

        </b-modal>

    </b-container>

  </div>
</template>

<script>

  import TableauDesComptes from '../../components/TableauDesComptes.vue';
  import CriteresDeRecherche from '../../components/CriteresDeRechercheTableau.vue';
  import url from '../../router/url.js';
  import inputChangerMotDePasse from '../../components/InputChangerMotDePasse.vue';
  const axios = require('axios');

  export default {
    components: {
      TableauDesComptes,
      CriteresDeRecherche,
      inputChangerMotDePasse,
    },
    data() {
      return {
        filter: '',
        parPage: 20,
        formNouveau: {
          nomUtilisateur : '', motDePasse : '', isAdmin : false
        },
        erreurCreationCompte : '',
        contientHuitCaracteres : false,
        contientAuMoinsUnChiffre : false,
        contientUnCaractereSpecial : false,
        motDePasseValide : false,
      }
    },
    methods: {
        afficheModalCreationCompte() {
          this.formNouveau.nomUtilisateur = '';
          this.formNouveau.motDePasse = '';
          this.contientHuitCaracteres = false;
          this.contientAuMoinsUnChiffre = false;
          this.contientUnCaractereSpecial = false;
          this.formNouveau.isAdmin = false;
          this.$bvModal.show('modalCreerUnNouveauCompte');
        },
        validationCreerUnNouveauCompte(event) {

          event.preventDefault();

          if (!this.motDePasseValide) {
            this.erreurCreationCompte = "Le mot de passe ne respecte pas les critères de sécurité demandés.";
            return;
          }

          var admin;

          if (this.formNouveau.isAdmin) {
            admin = 'O';
          } else {
            admin = 'N';
          }

          var formData = new FormData();
          formData.append('libelle', this.formNouveau.nomUtilisateur);
          formData.append('motDePasse', this.formNouveau.motDePasse);
          formData.append('admin', admin);

          axios.post(url.CreationCompte, formData).then(response => {

            var isAdded = response.data == "1";
            var compteExistant = response.data == "0";

            if (isAdded) {
              this.$refs.tableauComptes.getComptesUtilisateurs();
              this.$bvModal.hide('modalCreerUnNouveauCompte');
            } else if (compteExistant) {
              this.erreurCreationCompte = "Le compte " + this.formNouveau.nomUtilisateur + " existe déjà.";
            } else {
              this.erreurCreationCompte = "Erreur lors de la création du compte.";
            }
          
          }).catch(function (error) {
            alert('Erreur lors de la création du compte. ' + error);
          });

        },
        setFiltre(value) {
        this.filter = value;
        },
        setParPage(value) {
          this.parPage = value;
        },
        setMotDePasse(valeur) {
        this.formNouveau.motDePasse = valeur;
        },
        setMotDePasseValide(valeur) {
          this.motDePasseValide = valeur;
        },
      },
  }

</script>

<style>

.is_valid { 
  color: rgba(136, 152, 170, 0.8); 
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  text-decoration-color: #2ecc71;
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.complexiteMotDePasse {
  border-left: 0.1px solid #cbd8e0;
  border-right: 0.1px solid #cbd8e0;
  border-bottom: 0.1px solid #cbd8e0;
  border-bottom-right-radius:3px;
  border-bottom-left-radius:3px;
  padding-top:10px;
  background-color: beige;
  font-size:14px;
}

</style>